.otp-Form {
  width: 260px;
  height: 350px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 30px;
  gap: 20px;
  position: relative;
  border-radius: 15px;
}

.mainHeading {
  font-size: 1.1em;
  color: rgb(15, 15, 15);
  font-weight: 700;
}

.otpSubheading {
  font-size: 1em;
  color: black;
  line-height: 17px;
  text-align: center;
  font-weight: 500;
}

.verifyButton {
  width: 150px;
  height: 30px;
  padding-top: 8px;
  padding-bottom: 10px;
  border: none;
  background-color: rgb(127, 129, 255);
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  transition-duration: 0.2s;
}

.resendBtn:hover,
.verifyButton:hover {
  background-color: rgb(144, 145, 255);
  transition-duration: 0.2s;
}

.resendNote {
  font-size: 1em;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.resendBtn {
  border-radius: 15px;
}

