.ck-editor__editable {
    max-height: 180px;
    min-height: 180px;
}

#content_analysis p {
    margin: 0;
}

#content_analysis ul {
    margin: 0;
}