.close {
    position: absolute;
    right: -40px;
    top: -1px;
    width: 32px;
    height: 32px;
    opacity: 0.7;
    background-color: rgb(206, 206, 206);
}

.close:hover {
    opacity: 1;
}

.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #000;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}