.custom-btn {
    width: 80px;
    height: 30px;
    border-radius: 6px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    font-size: 15px;
  }
  
  .btn-2 {
    border-radius: 6px;
    border: 0px solid #000;
    background-color: #8BC1FF;
    color:#0D4381;
  }
  
  .btn-2:before {
    height: 0%;
    width: 2px;
  }
  
  .btn-2:hover {
    box-shadow: inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
  }
  
  .active-btn {
    border-radius: 6px;
    border: 0px solid #000;
    background-color: #0D4381;
    color:white;
  }
  
  .active-btn:before {
    height: 0%;
    width: 2px;
  }
  
  .active-btn:hover {
    box-shadow: inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
  }
  