.input-industry .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: #E6EFF9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    border: 1px solid #0D4381;
}

.input-industry .ant-select .ant-select-selection-item {
    color: #0D4381;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
}

.input-industry .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.25);
}

.input-industry .ant-select .ant-select-arrow .anticon {
    visibility: hidden;
}

.input-industry .ant-select .ant-select-arrow {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path d="M7 11L0.937822 0.5L13.0622 0.5L7 11Z" fill="%2377B3F8"/></svg>');
}


.input-watchlist .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: #E6EFF9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.input-watchlist .ant-select .ant-select-selection-item {
    color: #0D4381;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
}

.input-watchlist .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.25);
}

.input-watchlist .ant-select .ant-select-arrow .anticon {
    visibility: hidden;
}

.input-watchlist .ant-select .ant-select-arrow {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path d="M7 11L0.937822 0.5L13.0622 0.5L7 11Z" fill="%2377B3F8"/></svg>');
}

.input-watchlist .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #0D4381
}

.input-watchlist .ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ff4d4f;
}

.input-watchlist .ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector:hover {
    border-color: #ffa39e;
}