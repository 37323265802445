.parameter.ant-input-outlined {
    border-color: #0D4381;
    background: #E6EFF9;
    color: #0D4381;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.parameter.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: #E6EFF9;
}
