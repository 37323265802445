#triangle-topright {
    width: 0;
    height: 0;
    border-top: 8px solid red;
    border-left: 8px solid transparent;
}

#triangle-bottomleft {
    width: 0;
    height: 0;
    border-bottom: 8px solid green;
    border-right: 8px solid transparent;
}