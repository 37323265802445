.table-antd-betasmart .ant-table-thead .ant-table-cell {
    background-color: #0050AD !important;
    font-size: 16px;
    font-weight: 700;
    color: white
}

.table-antd-betasmart .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0;

}

.table-antd-betasmart .ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
    background: white;
}

.table-antd-betasmart .ant-table-wrapper .ant-table-cell-fix-left {
    background: none;
}

.table-antd-betasmart .ant-table-wrapper .ant-table-row:nth-child(odd) .ant-table-cell {
    background-color: #b7d4ff;
} 

.table-antd-betasmart .ant-table-wrapper .ant-table-row:nth-child(even) .ant-table-cell {
    background-color: white;
} 

.table-antd-betasmart .ant-table-wrapper td.ant-table-column-sort {
    background: none;
}

.table-antd-betasmart .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-radius: 0;
}

.table-antd-betasmart .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-radius: 0;
}

.table-antd-betasmart .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 5px 16px;
}
