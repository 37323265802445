.button {
  padding: 13px 30px;
  border-radius: 10px;
  border: 0;
  background-color: rgb(255, 56, 86);
  letter-spacing: 1.5px;
  font-size: 15px;
  transition: all 0.3s ease;
  box-shadow: rgb(201, 46, 70) 0px 10px 0px 0px;
  color: hsl(0, 0%, 100%);
}

.button:hover {
  box-shadow: rgb(201, 46, 70) 0px 7px 0px 0px;
}

.button:active {
  background-color: rgb(255, 56, 86);
  /*50, 168, 80*/
  box-shadow: rgb(201, 46, 70) 0px 0px 0px 0px;
  transform: translateY(5px);
  transition: 200ms;
}
.swal2-container {
  z-index: 10000; /* Chọn một giá trị đủ lớn để đảm bảo nó ở trên cùng */
}
